import React, { Fragment, useMemo } from 'react';
import useNetworkAccounts from 'store/redux/hooks/useNetworkAccounts';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import HeaderButton from '../HeaderButton/HeaderButton';
import { Menu, Popover, Typography } from 'antd';
import NetworkAccountSingleItem from './NetworkAccountSingleItem';

export default function AccountChooser() {
  const { accounts, isLoading: isAccountsLoading } = useNetworkAccounts({});

  const menu = useMemo(() => {
    const _menu = [];

    /** @type {Array<Network>} */
    const networks = ['facebook', 'google'];

    networks.forEach((network) => {
      const icon = <img src={`/icons/${network}.svg`} alt='' className='w-3 ml-px' />;
      const network_accounts = accounts.filter((acc) => acc.network === network);

      _menu.push({
        key: network,
        icon: icon,
        label: network === 'facebook' ? 'Meta' : 'Google',
        children: network_accounts
          .filter((acc) => acc.is_manager || network)
          .map((acc) => {
            return {
              key: acc.id,
              icon: icon,
              label: <NetworkAccountSingleItem account={acc} />,
            };
          }),
      });
    });

    return _menu;
  }, [accounts]);

  const facebook = accounts.filter((acc) => acc.network === 'facebook');
  const google = accounts.filter((acc) => acc.network === 'google');

  const active_fb_count = facebook?.filter((f) => f.active).length;
  const active_google_count = google?.filter((f) => f.active).length;

  return (
    <Fragment>
      <HeaderButton style={{ border: 0, display: 'grid' }} color='darkText'>
        <Popover
          overlayClassName='[&_.ant-popover-inner]:!p-0 w-[300px]'
          zIndex={9999999999}
          content={
            <Menu
              inlineIndent={12}
              triggerSubMenuAction='click'
              onClick={() => {}}
              mode='inline'
              className='
                [&_ul.ant-menu-sub]:max-h-[400px] 
                [&_ul.ant-menu-sub]:overflow-y-scroll
              '
              items={menu}
            />
          }
          trigger='click'
          // open={true}
        >
          <div className='leading-3 text-left space-x-2.5 flex items-center'>
            <AccountTreeIcon />
            <span>Choose Accounts</span>
          </div>
          <div className='flex space-x-3.5 align-center mt-px'>
            <img src='/icons/facebook.svg' alt='facebook' className='w-3 ml-px' />
            <Typography className='!text-xs !font-normal'>{active_fb_count} Active</Typography>
          </div>
          <div className='flex space-x-3.5 items-center'>
            <img src='/icons/google.svg' alt='google' className='w-3 ml-px' />
            <Typography className='!text-xs !font-normal'>{active_google_count} Active</Typography>
          </div>
        </Popover>
      </HeaderButton>
    </Fragment>
  );
}
