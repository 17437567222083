import Button from '@material-ui/core/Button';
import { mdiCalendarMonth } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import TextField from '../TextField';
import { Popover, Button as AntButton } from 'antd';
import clsx from 'clsx';
import DateRange from './DateRange';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';
import { addDays } from 'date-fns';

const DateRangePicker = (props) => {
  const {
    variant = 'button',
    onChange,
    changeGlobal = false,
    date_key,
    formatter = 'dd MMM',
    showExpandDays = false,
    ...rest
  } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { date_from, date_to, setDate } = useWidgetDate(date_key);
  const initialStartDate = date_from ? new Date(date_from) : new Date();
  const initialEndDate = date_to ? new Date(date_to) : new Date();

  const [state, setState] = useState({
    range: {
      startDate: initialStartDate,
      endDate: initialEndDate,
      key: 'range',
    },
  });

  useEffect(() => {
    setState({
      range: {
        startDate: initialStartDate,
        endDate: initialEndDate,
        key: 'range',
      },
    });
  }, [date_from, date_to]);

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const handleDateChange = (newStartDate, newEndDate) => {
    setDate({ date_from: newStartDate, date_to: newEndDate });
    setState({
      range: {
        startDate: newStartDate,
        endDate: newEndDate,
        key: 'range',
      },
    });
  };

  useEffect(() => {
    const date_from_input = Date.format(state.range.startDate);
    const date_to_input = Date.format(state.range.endDate);

    onChange && onChange({ date_from: date_from_input, date_to: date_to_input });
  }, [state]);

  const textField = `${Date.format(state.range.startDate, formatter) || '-'}  -  ${
    Date.format(state.range.endDate, formatter) || '-'
  }`;

  const variants = {
    button: {
      component: Button,
      props: {
        className: '!bg-background-light !rounded-[46px] !text-color-dark-text !font-medium !h-10',
        endIcon: <Icon className='text-blue' path={mdiCalendarMonth} />,
        variant: 'contained',
        color: 'primary',
        children: textField,
        ...rest,
      },
    },
    input: {
      component: TextField,
      className: '!text-xs',
      props: {
        variant: 'outlined',
        fullWidth: true,
        value: textField,
        InputProps: {
          readOnly: true,
          endAdornment: <Icon className='text-orange' path={mdiCalendarMonth} />,
        },
        ...rest,
      },
    },
  };

  const Component = variants[variant].component;

  return (
    <div
      className={clsx(classes.container, {
        'flex items-center gap-1': showExpandDays,
      })}
    >
      {showExpandDays ? (
        <AntButton
          onClick={() => handleDateChange(addDays(state.range.startDate, -10), state.range.endDate)}
          size='medium'
          shape='round'
        >
          -10 Days
        </AntButton>
      ) : null}
      <Popover
        className={classes.date}
        placement='bottom'
        open={open}
        onOpenChange={handleOpenChange}
        trigger='click'
        content={
          <DateRange
            date_key={date_key}
            globalDate={changeGlobal}
            onChange={(range) => {
              setState(range);
              if (range.range.startDate !== range.range.endDate) {
                setOpen(false);
              }
            }}
            value={state}
          />
        }
      >
        <Component {...variants[variant].props} />
      </Popover>
      {showExpandDays ? (
        <AntButton
          className={classes.button}
          onClick={() => handleDateChange(state.range.startDate, addDays(state.range.endDate, +10))}
          size='medium'
          shape='round'
        >
          +10 Days
        </AntButton>
      ) : null}
    </div>
  );
};

export default DateRangePicker;

const useStyles = makeStyles(() => ({
  date: {
    height: '100%',
    '& .MuiOutlinedInput-input': {
      padding: 0,
      cursor: 'pointer',
    },
    '& .MuiInputBase-root': {
      minHeight: '30px !important',
      borderRadius: '8px',
      paddingLeft: '14px',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: '0px',
    },
    '& .ant-popover-content ': {
      padding: '0px !important',
    },
  },
  container: {
    cursor: 'pointer !important',
    height: '100%',
    '& .MuiButton-label': {
      fontSize: '12px !important',
    },
    '& .MuiFormControl-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#ff4e00 !important',
      borderWidth: '1px !important',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#ff4e00 !important',
      borderWidth: '1px !important',
      cursor: 'pointer',
    },
    '& .MuiOutlinedInput-input': {
      fontSize: 12,
      fontWeight: 500,
      cursor: 'pointer',
    },
    '& .MuiOutlinedInput-root': {
      height: '100%',
      cursor: 'pointer',
    },
  },
}));
