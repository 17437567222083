import { CssBaseline, LinearProgress } from '@material-ui/core';
import '@ninja';
import App from './App';
import MuiThemeWrapper from 'common/@mui/theme';
import { SnackbarProvider } from 'notistack';
import React, { Suspense } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from 'store/redux/store';
import { NotificationsContextProvider } from 'store/NotificationsContext';
import { ConfigProvider } from 'antd';
import './styles/index.css';
import { UrlContextProvider } from 'hooks/useQueryParams';

const Bootstrap = () => {
  return (
    <BrowserRouter>
      <MuiThemeWrapper>
        <ReduxProvider store={store}>
          <CssBaseline />
          <SnackbarProvider
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <NotificationsContextProvider>
              <UrlContextProvider>
                <Suspense fallback={<LinearProgress />}>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: '#ff4e00',
                        colorSuccess: '#91c716',
                        colorWarning: '#ffbb02',
                        colorError: '#eb2f20',
                        colorInfo: '#2c7df0',
                        colorTextBase: '#151a22',
                        wireframe: false,
                        fontSize: 16,
                        fontFamily: 'fregular',
                      },
                      components: {
                        Input: {
                          controlHeightLG: 60,
                          controlHeightSM: 35,
                          inputFontSizeLG: '14px',
                          inputFontSizeSM: '14px ',
                        },
                        Select: {
                          controlHeightLG: 53,
                          fontSizeLG: 14,
                          optionFontSize: 12,
                        },
                        Form: {
                          verticalLabelPadding: '0px 5px 2px',
                          itemMarginBottom: '8px',
                          labelFontSize: '12px',
                          fontSize: 12,
                          fontWeight: 600,
                        },
                        Alert: {
                          withDescriptionPadding: '10px 15px',
                          colorWarningBg: '#FEF4E4',
                          colorWarningBorder: '#FFBB02',
                          colorInfoBg: '#F0F6FF',
                          colorInfoBorder: '#536A8B',
                          colorErrorBg: '#FBECEA',
                          colorErrorBorder: '#EB2F20',
                          colorSuccessBg: '#F3FAE3',
                          colorSuccessBorder: '#91C716',
                        },
                        Menu: {
                          itemSelectedBg: '#FBECEA',
                          itemSelectedColor: '#ff4e00',
                        },
                        Tabs: {
                          margin: 0,
                          colorText: '#2c435a',
                          itemActiveColor: '#2c435a',
                          titleFontSize: 14,
                          cardBg: ' rgba(0, 0, 0, 0.05)',
                        },
                        Button: {
                          contentFontSize: 14,
                          contentFontSizeLG: 14,
                          defaultHoverBorderColor: '#ff4e00',
                          defaultHoverColor: '#ff4e00',
                        },
                        Checkbox: {
                          fontSize: 14,
                          lineWidth: 2,
                          fontFamily: 'fregular',
                        },
                        Collapse: {
                          headerBg: '#fff',
                          colorBorder: '#fff',
                        },
                        Descriptions: {
                          titleMarginBottom: '8px',
                        },
                        Divider: {
                          marginLG: '5px',
                        },
                        Radio: { fontSize: 14 },
                        Switch: {
                          colorPrimary: 'rgb(145, 199, 22)',
                        },
                        Segmented: {
                          itemSelectedColor: '#ff4e00',
                        },
                        Pagination: {
                          itemActiveBg: '#FBECEA',
                          fontFamily: 'fregularBold',
                          fontSize: 12,
                        },
                        Card: {
                          paddingLG: 10,
                          colorSplit: '#d5d5d5',
                        },
                        Typography: {
                          fontSizeHeading5: '12px',
                          fontSizeHeading4: '14px',
                        },
                      },
                    }}
                  >
                    <App />
                  </ConfigProvider>
                </Suspense>
              </UrlContextProvider>
            </NotificationsContextProvider>
          </SnackbarProvider>
        </ReduxProvider>
      </MuiThemeWrapper>
    </BrowserRouter>
  );
};

export default Bootstrap;
