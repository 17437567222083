import React, { useState, useEffect } from 'react';
import { Popover, Select } from 'antd';
import { Button, IconButton, makeStyles } from '@material-ui/core';
import { useNinjaTable } from 'components/tables/NinjaTable/useNinjaTable';
import { mdiCogOutline, mdiDownloadOutline, mdiLink, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import TableColumnCustomization from 'components/tables/NinjaTable/TableColumnCustomization';
import SavedFilter from 'components/tables/NinjaTable/SavedFilter';
import { AccordionDetails, AccordionSummary, Accordion } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { mdiFilterOutline, mdiTune, mdiFileTree, mdiViewHeadline, mdiFilterVariant } from '@mdi/js';
import RadioInput from 'components/inputs/RadioInput';

const NinjaTableCustomize = (props) => {
  const classes = useStyles();
  const {
    changeLevel,
    customizationId,
    changeLimit,
    onCustomizationUpdate,
    exportData = false,
    customizationEdit,
    removeColumns,
    defaultLevel,
    disableLevelFilter,
    levelOption,
    savedFilters = true,
    defaultPerPage,
    disableLimit,
    copyUrl = false,
    renderFilter,
    ...rest
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [level, setLevel] = useState(defaultLevel);
  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(null);

  useEffect(() => {
    setLevel(defaultLevel);
  }, [defaultLevel]);

  const { onExport } = useNinjaTable();

  const handleCopy = async () => {
    setIsLoading(true);
    navigator.clipboard.writeText(window.location.href);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const style = {
    width: '350px',
  };

  const redirectToFilter = () => {
    handleChange('filters');
    setExpanded('filters');
  };

  return (
    <Popover
      placement='bottomLeft'
      trigger='click'
      open={open}
      onOpenChange={handleOpenChange}
      content={
        <div className={classes.root} style={style}>
          <div className='flex items-center justify-between'>
            <span className='text-sm font-semibold'>Customize Table</span>
            <IconButton onClick={handleClose}>
              <Icon path={mdiClose} className='w-3 h-3' />
            </IconButton>
          </div>
          {renderFilter && (
            <>
              <Popover
                placement='left'
                content={renderFilter}
                trigger='click'
                // open={expanded === 'filters' ? true : false}
              >
                <Accordion expanded={expanded === 'filters'} onChange={handleChange('filters')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className='flex items-center gap-1'
                    aria-controls='filters-content'
                    id='filters-header'
                  >
                    <Icon path={mdiFilterVariant} className='text-background-dark' size={0.6} />
                    <span className='!text-sm  text-background-dark'>Filters</span>
                  </AccordionSummary>
                </Accordion>
              </Popover>
              {savedFilters && (
                <Accordion
                  expanded={expanded === 'savedFilters'}
                  onChange={handleChange('savedFilters')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className='flex items-center gap-1'
                    aria-controls='savedFilters-content'
                    id='savedFilters-header'
                  >
                    <Icon path={mdiFilterOutline} className='text-background-dark' size={0.6} />
                    <span className='!text-sm  text-background-dark'>Saved Filters</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <SavedFilter handleRedirect={redirectToFilter} />
                  </AccordionDetails>
                </Accordion>
              )}
            </>
          )}
          {customizationId && (
            <Accordion expanded={expanded === 'columns'} onChange={handleChange('columns')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className='flex items-center gap-1'
                aria-controls='columns-content'
                id='columns-header'
              >
                <Icon path={mdiTune} className='text-background-dark' size={0.6} />
                <span className='!text-sm  text-background-dark'>Columns</span>
              </AccordionSummary>
              <AccordionDetails>
                <TableColumnCustomization
                  customizationId={customizationId}
                  onCustomizationUpdate={onCustomizationUpdate}
                  customizationEdit={customizationEdit}
                  removeColumns={removeColumns}
                />
              </AccordionDetails>
            </Accordion>
          )}
          {!disableLevelFilter && (
            <>
              <span className={classes.line} />
              <Accordion expanded={expanded === 'level'} onChange={handleChange('level')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  className='flex items-center gap-1'
                  aria-controls='level-content'
                  id='level-header'
                >
                  <Icon path={mdiFileTree} className='text-background-dark' size={0.6} />
                  <span className='!text-sm  text-background-dark'>Change Level</span>
                  <span className='text-xs text-gray ml-auto'>{level}</span>
                </AccordionSummary>
                <AccordionDetails>
                  <RadioInput
                    value={level}
                    fontSize='12px'
                    color='black'
                    row
                    onChange={changeLevel}
                    options={levelOption}
                  />
                </AccordionDetails>
              </Accordion>
            </>
          )}
          {!disableLimit && (
            <Accordion onChange={handleChange('perPage')}>
              <AccordionSummary className='flex items-center gap-1'>
                <Icon path={mdiViewHeadline} className='text-background-dark' size={0.6} />
                <span className='!text-sm  text-background-dark'>Per Page</span>
                <Select
                  onChange={changeLimit}
                  className=' ml-auto'
                  options={[
                    { value: '5', label: 5 },
                    { value: '10', label: 10 },
                    { value: '20', label: 20 },
                    { value: '25', label: 25 },
                    { value: '50', label: 50 },
                  ]}
                  value={defaultPerPage}
                />
              </AccordionSummary>
            </Accordion>
          )}

          {copyUrl && (
            <>
              <span className={classes.line} />
              <AccordionSummary className='flex items-center gap-1' onClick={handleCopy}>
                <Icon path={mdiLink} className='!w-4 !h-4 text-background-dark' />
                <span className='font-normal'>{isLoading ? 'copied!' : 'Copy Link'}</span>
              </AccordionSummary>
            </>
          )}
          {exportData && (
            <AccordionSummary className='flex items-center gap-1' onClick={onExport}>
              <Icon path={mdiDownloadOutline} className='!w-4 !h-4 text-background-dark' />
              <span className='font-normal  text-background-dark'>Export</span>
            </AccordionSummary>
          )}
        </div>
      }
    >
      <Button
        color='default'
        size='small'
        type='primary'
        onClick={() => setOpen(!open)}
        className='flex items-center justify-between !h-9'
        variant='outlined'
      >
        <Icon path={mdiCogOutline} className='mr-2 text-background-dark' size={0.6} />
        <span className='font-normal text-sm text-background-dark'>Customize</span>
      </Button>
    </Popover>
  );
};

export default NinjaTableCustomize;

const useStyles = makeStyles((theme) => ({
  line: {
    borderTop: '1px solid lightgrey',
    height: 1,
    width: '100%',
    margin: '5px 0px',
  },
  root: {
    display: 'grid',
    '& .MuiAccordion-root.Mui-expanded': {
      margin: 0,
    },
    '& .MuiAccordion-root': {
      boxShadow: 'none',
    },
    '& .MuiAccordionSummary-root': {
      padding: '0px 5px',
      margin: '0px',
      height: '35px',
      minHeight: '35px',
    },
    '& .MuiAccordionSummary-root:hover': {
      backgroundColor: '#d5d5d542 !important',
    },
    '& .MuiAccordionSummary-content': {
      alignItems: 'center',
      gap: '10px',
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      height: '35px',
      minHeight: '35px',
      backgroundColor: '#d5d5d542 !important',
    },
    ' & .MuiAccordionSummary-content.Mui-expanded': {
      margin: 0,
    },
    '& .MuiIconButton-edgeEnd': {
      margin: 0,
    },
    '& .MuiCollapse-root': {
      width: '100%',
      padding: '0px 10px',
    },
    '& .MuiAccordionDetails-root': {
      padding: 0,
      margin: '10px 0px',
    },
    width: '99%',
  },
}));
