import ninjaApi from 'store/redux/apis/ninja.api';

/**
 * Woocommerce connection api
 */
export const woocommerceApi = ninjaApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    listWoocommerce: build.query({
      query: (data) => ({
        url: '/apps/woocommerce/list',
        method: 'POST',
        data,
      }),
      providesTags: ['Woocommerce'],
    }),
    checkWoocommerceStoreUrl: build.mutation({
      query: (data) => ({
        url: '/apps/woocommerce/check-domain',
        method: 'POST',
        data,
      }),
    }),
    requestWoocommerceAuthorization: build.mutation({
      query: (data) => ({
        url: '/apps/woocommerce/request-authorization',
        method: 'POST',
        data,
      }),
    }),
    integrateWoocommerceStore: build.mutation({
      query: (data) => ({
        url: '/apps/woocommerce/integrate',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Woocommerce'],
    }),
  }),
});

export const {
  useListWoocommerceQuery,
  useCheckWoocommerceStoreUrlMutation,
  useRequestWoocommerceAuthorizationMutation,
  useIntegrateWoocommerceStoreMutation,
} = woocommerceApi;
