import { Avatar } from 'antd';
import clsx from 'clsx';
import React from 'react';

const NetworkIcon = ({ network, size = 15, ...rest }) => {
  const iconPath = `/icons/${network}.svg`;

  if (!network) {
    return null;
  }

  return network === 'all' ? null : <Avatar size={size} src={iconPath} {...rest} />;
};

export default NetworkIcon;
