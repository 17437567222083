import React from 'react';
import NinjaTable from 'components/tables/NinjaTable';
import RoiFormatter from 'components/tables/NinjaTable/formatters/RoiFormatter';
import InnerProfitForrmatter from 'components/tables/NinjaTable/formatters/InnerProfitForrmatter';
import { JustValueFormatter, TotalValueFormatter } from 'components/tables/NinjaTable/formatters';
import { useParams } from 'react-router-dom';
import NameDefinition from 'components/NameDefinition';

const columns = [
  {
    key: 'date',
    name: 'Date',
    flex: 1,
    minWidth: 100,
    renderSummaryCell() {
      return <strong className='text-xs'>Total</strong>;
    },
  },
  {
    key: 'leads_unique',
    name: 'Leads',
    // width: 100,
    renderSummaryCell: (data) => {
      return <span className='font-bold text-xs'>{data.row.leads_unique}</span>;
    },
  },
  {
    key: 'sale_count',
    name: 'Sale',
    renderSummaryCell: (data) => {
      return <span className='font-bold text-xs'>{data.row.sale_count}</span>;
    },
  },
  {
    key: 'cpl',
    name: <NameDefinition name='CPL' definiton='Cost Per Lead' />,
    renderCell: JustValueFormatter,
    renderSummaryCell: TotalValueFormatter,
  },
  {
    key: 'income',
    name: 'Income',
    renderCell: JustValueFormatter,
    renderSummaryCell: TotalValueFormatter,
  },
  {
    key: 'spent',
    name: 'Spent',
    renderCell: JustValueFormatter,
    renderSummaryCell: TotalValueFormatter,
  },
  {
    key: 'profit',
    name: 'Profit',
    type: 'profit',
    width: 100,
    renderCell: InnerProfitForrmatter,
    renderSummaryCell: InnerProfitForrmatter,
  },
  {
    key: 'roi',
    name: <NameDefinition name='ROI' definiton='Return on Investment' />,
    renderCell: RoiFormatter,
    renderSummaryCell: RoiFormatter,
  },
];

const DailyStatisticsCustomData = ({ innerId, moduleId, hideTitle = false }) => {
  const { id: formId } = useParams();
  const customizationId = `daily-${formId}`;

  return (
    <div className='mt-5 truncate'>
      <NinjaTable
        loadOnView
        datepicker
        showExpandDays={true}
        date_key='daily_statistics'
        dataProvider={
          moduleId === 'reporting' ? 'adsetDailyStatisticsData' : 'urlDailyStatisticsData'
        }
        footerProps={{ showPerPage: false }}
        columns={columns}
        queryParams={{
          [moduleId === 'reporting' ? 'id' : 'url_id']: innerId,
          resource: 'adset',
        }}
        title={hideTitle ? '' : 'Daily Statistics'}
        customize
        customizationId={customizationId}
      />
    </div>
  );
};

export default DailyStatisticsCustomData;
