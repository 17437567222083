import React from 'react';
import { Tooltip } from 'antd';
import Icon from '@mdi/react';
import { mdiHelpCircleOutline  } from '@mdi/js';

const NameDefinition = ({ name, definiton, placement = 'right', size = 0.6 }) => {
  return (
    <Tooltip
      title={definiton}
      placement={placement}
      className='flex items-center gap-1 cursor-pointer'
    >
      <span className='text-xs'>{name}</span>
      <Icon path={mdiHelpCircleOutline } size={size} />
    </Tooltip>
  );
};

export default NameDefinition;
