import clsx from 'clsx';
import React from 'react';

const RoiFormatter = ({ row: { roi } }) => {
  return (
    <div>
      <span
        className={clsx('px-[7px] py-[5px] rounded-[12px] !text-sm', {
          'bg-error text-white': roi <= 0,
          'bg-warning text-white': roi > 0 && roi < 30,
          'bg-success text-white': roi > 30,
        })}
      >
        {roi}%
      </span>
    </div>
  );
};

export default RoiFormatter;
